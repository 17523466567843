import Vue from 'vue'
import VueRouter from 'vue-router'

import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import home from '@/router/home'
import product from '@/router/product'
import auth from '@/router/auth'
import other from '@/router/other'
import trendyol from '@/router/trendyol'
import order from '@/router/order'
import invoice from '@/router/invoice'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
    routes: [
        ...home,
        ...product,
        ...auth,
        ...other,
        ...trendyol,
        ...order,
        ...invoice,
        {
            path: '/deneme/barcode',
            name: 'home',
            component: () => import('@/views/deneme/barcode.vue')
        }
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()
    if (!canNavigate(to)) {
        if (!isLoggedIn) return next({name: 'auth-login'})
        return next({name: 'notauthorized'})
    }
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
    return next()
})

router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) appLoading.style.display = 'none'
})

export default router
