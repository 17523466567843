import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        products: [],
        pagination: null,
        filter: {
            blacklisted: null,
            tyProduct: {
                isMulti: 'all',
                isMatch: 'all',
                product_id: '',
                merchant_sku: ''
            },
            product: {
                id: '',
                barcode: '',
                name: '',
                brandIds: [],
            },
            page: 0,
            limit: 25,
        },
    },
    getters: {
        _products(state) {
            return state.products
        },
        _pagination(state) {
            return state.pagination
        },
        _filter(state) {
            return state.filter
        },
    },
    mutations: {
        setProducts(state, products) {
            state.products = products
        },
        setPagination(state, pagination) {
            state.pagination = pagination
        }
    },
    actions: {
        getProducts(context) {
            context.commit('setProducts', [])
            axios.post('/trendyol/product', context.state.filter)
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setProducts', res.data.content.data)
                        delete res.data.content.data
                        context.commit('setPagination', res.data.content)
                    }
                })
        },
        updateProduct(context, payload) {
            return axios.put('/trendyol/product/' + payload.id, payload)
        },
    },
}
