<template>
  <div id="app-container">
    <div>
      <b-btn variant="danger" @click="stopScanner">Durdur</b-btn>
      <b-btn variant="success" @click="startScanner">Başlat</b-btn>
    </div>
    <div id="scanner-container"></div>
    <div>
      <p>Barkod: {{ scannedCodeList }}</p>
    </div>
  </div>
</template>

<script>
import Quagga from "quagga";

export default {
  data() {
    return {
      scannedCodeList: [],
    };
  },
  methods: {
    onBarcodeDetected(result) {
      if (result && result.codeResult && result.codeResult.code) {
        this.scannedCodeList.push(result.codeResult.code);
      }
    },
    startScanner() {
      Quagga.init({
            inputStream: {
              name: "Live",
              type: "LiveStream",
              target: document.querySelector("#scanner-container"),
            },
            decoder: { readers: ["code_128_reader", "ean_reader", "upc_reader"] },
          },
          (err) => {
            if (err) {
              console.error("Quagga başlatma hatası:", err);
              return;
            }
            Quagga.start();
          });
      Quagga.onDetected(this.onBarcodeDetected);
    },
    stopScanner() {
      Quagga.stop();
      const tracks = Quagga.CameraAccess.getActiveTrack();
      if (tracks) {
        tracks.stop();
      }
      Quagga.offDetected(this.onBarcodeDetected);
    },
  },
  beforeDestroy() {
    this.stopScanner();
  },
};
</script>

<style>
#app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

b-btn {
  margin: 10px;
}

#scanner-container {
  width: 90vw;
  max-width: 500px;
  height: 90vw;
  max-height: 500px;
  overflow: hidden;
  position: relative;
  background-color: #f4f4f4;
  border: 2px solid #ccc;
  border-radius: 10px;
}

#scanner-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

p {
  margin-top: 20px;
  font-size: 1.2em;
  color: #333;
}
</style>
