<template>
  <b-card no-body>
    <b-overlay :show="isShow" rounded="lg" opacity="0.6">
      <b-card-body>
        <b-row>
          <b-col md="2">
            <b-img-lazy
                v-for="(image,index) in product.images"
                :src="image.url"
                :key="image.id"
                v-if="index==0"
                rounded
                width="150"
                left class="mb-1 mb-sm-0"
            />
          </b-col>
          <b-col md="2">
            <div v-if="product.product && product.product.images">
              <b-img-lazy
                  v-for="(image,index) in product.product.images"
                  :src="siteUrl + image.url"
                  :key="image.id"
                  v-if="index==0"
                  rounded
                  width="150"
                  left class="mb-1 mb-sm-0"
              />
            </div>

          </b-col>
          <b-col md="1" class="status-column">
            <div class="status-row" @dblclick="changeStatus('approved', !product.approved)">
              <span class="status-name">Durum:</span>
              <feather-icon
                  :icon="product.approved ? 'CheckIcon' : 'XIcon'"
                  :class="product.approved ? 'text-success' : 'text-danger'"
              />
            </div>
            <div class="status-row" @dblclick="changeStatus('blacklisted', !product.blacklisted)">
              <span class="status-name">Kara Liste:</span>
              <feather-icon
                  :icon="product.blacklisted ? 'CheckIcon' : 'XIcon'"
                  :class="product.blacklisted ? 'text-success' : 'text-danger'"
              />
            </div>
            <div class="status-row" @dblclick="changeStatus('archived', !product.archived)">
              <span class="status-name">Arşiv:</span>
              <feather-icon
                  :icon="product.archived ? 'CheckIcon' : 'XIcon'"
                  :class="product.archived ? 'text-success' : 'text-danger'"
              />
            </div>
            <div class="status-row" @dblclick="changeStatus('locked', !product.locked)">
              <span class="status-name">Kilit:</span>
              <feather-icon
                  :icon="product.locked ? 'CheckIcon' : 'XIcon'"
                  :class="product.locked ? 'text-success' : 'text-danger'"
              />
            </div>
            <div class="status-row" @dblclick="changeStatus('onsale', !product.onsale)">
              <span class="status-name">Satışta:</span>
              <feather-icon
                  :icon="product.onsale ? 'CheckIcon' : 'XIcon'"
                  :class="product.onsale ? 'text-success' : 'text-danger'"
              />
            </div>
            <div class="status-row" @dblclick="changeStatus('hasActiveCampaign', !product.hasActiveCampaign)">
              <span class="status-name">Kampanyada:</span>
              <feather-icon
                  :icon="product.hasActiveCampaign ? 'CheckIcon' : 'XIcon'"
                  :class="product.hasActiveCampaign ? 'text-success' : 'text-danger'"
              />
            </div>
          </b-col>
          <b-col md="5">
            <b-row>
              <b-col md="6">
                <h4 style="margin-top: 20px">
                  <a v-if="product.ty_product_list.product_content_id"
                     :href="'https://www.trendyol.com/w/w-w-p-'+product.ty_product_list.product_content_id"
                     target="mpProduct"
                  >
                    <feather-icon icon="ExternalLinkIcon"/>
                  </a>
                  {{ product.ty_product_list.product_name }}
                </h4>
                <b-badge>{{ product.merchant_sku }}</b-badge>
                <b-badge variant="danger">{{ product.multiple }}x</b-badge>
                <hr>
                Min : {{ product.price_min }} Max : {{ product.price_max }}<br>
                Fiyat : {{ product.price_send }}<br>
                Stok : {{ product.stock_send }}<br>
                Komisyon : {{ product.commission }}<br>
              </b-col>
              <b-col md="6">
                <div v-if="product.product">
                  <h4 style="margin-top: 20px">
                    <a>
                      <feather-icon icon="ExternalLinkIcon"/>
                      {{ product.product.name }}
                    </a>
                  </h4>
                  <b-badge>{{ product.product.barcode }}</b-badge>
                  <b-badge v-if="product.product.isBundle" variant="danger">
                    Paket Ürün
                  </b-badge>
                  <b-badge v-else variant="success">
                    Ürün
                  </b-badge>
                  <hr>
                  Fiyat : {{ product.product.purchase_price }}<br>
                  Stok : {{ product.product.quantity }}<br>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <Seller v-for="(seller,key1) in product.ty_product_list.sellers" :key="key1" :seller="seller"
                        :product="product"
                        :index="key1"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2" style="margin-top: 20px">
            <div class="text-nowrap">
              <b-input-group>
                <b-form-input type="number" placeholder="Sabit Fiyat" v-model="product.price_static"/>
                <b-input-group-append>
                  <b-button variant="outline-primary"
                            @click="saveProduct({
                        id:product.id,
                        price_static:product.price_static
                        })"
                  >
                    <feather-icon icon="SaveIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group>
                <b-form-input type="number" placeholder="Ürün Id" v-model="product.product_id"/>
                <b-input-group-append>
                  <b-button variant="outline-primary"
                            @click="saveProduct({
                        id:product.id,
                        product_id:product.product_id
                        })"
                  >
                    <feather-icon icon="SaveIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <b-input-group>
                <b-form-input type="number" placeholder="Kat sayı" v-model="product.multiple"/>
                <b-input-group-append>
                  <b-button variant="outline-primary"
                            @click="saveProduct({
                        id:product.id,
                        multiple:product.multiple
                        })"
                  >
                    <feather-icon icon="SaveIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-badge variant="danger">{{ product.multiple }}x</b-badge>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import Seller from '@/views/trendyol/product/Seller.vue'

export default {
  name: 'TrendyolProductList',
  components: {Seller},
  data: () => ({
    isShow: false,
  }),
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  computed: {
    siteUrl() {
      return process.env.VUE_APP_IMG_URL
    },
  },
  methods: {
    changeStatus(key, value) {
      this.isShow = true
      this.$store.dispatch('trendyol/updateProduct', {id: this.product.id, [key]: value})
          .then(res => {
            this.isShow = false
            if (res.data.content) {
              this.product[key] = value
            }
          })
    },
    saveProduct(payload) {
      this.isShow = true
      this.$store.dispatch('trendyol/updateProduct', payload)
          .then(res => {
            this.isShow = false
          })
    },
    saveMultiple(payload) {
      this.isShow = true
      this.$store.dispatch('trendyol/updateProduct', payload)
          .then(res => {
            this.isShow = false
          })
    },
  },
}
</script>

<style scoped>
.status-column {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Satır aralıklarını artır */
}

.status-row {
  display: flex;
  align-items: center;
  gap: 12px; /* İsim ve ikon arasına boşluk ekle */
}


</style>
